const { serialize } = require("../../utils");
export default {
  namespaced: true,
  state: {
    path: "aljabr/telegram-surveys",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/?${serialize(options)}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    add({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/add`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    pause({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/pause`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    play({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/play`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    remind({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/remind`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    report({ dispatch, state, rootState }, id) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/report`,
            method: "POST",
            data: { id },
          },
          { root: true }
        );
      }
    },
    addAnnouncement({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/add-announcement`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    addAnnouncementWithVideo({ dispatch, state, rootState }, data) {
      console.log("🚀 ~ addAnnouncementWithVideo ~ data:", data);
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/add-announcement-with-video`,
            method: "VID",
            data,
          },
          { root: true }
        );
      }
    },
    announcements({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/announcements?${serialize(options)}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    todaysMeals({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/todays-meals?${serialize(options)}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    addTodaysMeal({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/add-todays-meal`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    groupsList({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/groups-list?${serialize(options)}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    branchesWithGroups({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/branches-with-groups?${serialize(options)}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
  },
};
