const { serialize } = require("../../utils");
export default {
  namespaced: true,
  state: {
    path: "aljabr/sciences",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/?${serialize(options)}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    add({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/add`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    save({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/save`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
  },
};
