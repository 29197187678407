export default {
  namespaced: true,
  state: {
    path: "abco/contracts",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.ABCO.branch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/?${Object.entries(options)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join("&")}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    list({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.ABCO.branch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/list?${Object.entries(options)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join("&")}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    check({ dispatch, state, rootState }, value) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/check/${value}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    add({ dispatch, state, rootState }, data) {
      data["branch"] = rootState.ABCO.branch._id;
      data["staffer"] = rootState.profile._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    id({ dispatch, state, rootState }, value) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/id/${value}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    teachers({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.ABCO.branch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/teachers?${Object.entries(options)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join("&")}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
  },
};
