export default {
  namespaced: true,
  state: {
    path: "aljabr/migration",
  },
  actions: {
    get({ dispatch, state, rootState }) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: state.path,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    run({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/run`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
  },
};
