const { serialize } = require("../../utils");
export default {
  namespaced: true,
  state: {
    path: "aljabr/academic-years",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        options["branch"] = rootState.aljabr.selectedBranch._id;
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/?${serialize(options)}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    list({ dispatch, state, rootState }) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/list/?branch=${rootState.aljabr.selectedBranch._id}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    forBranch({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/list/?${serialize(options)}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    last({ dispatch, state, rootState }) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/last/?branch=${rootState.aljabr.selectedBranch._id}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    add({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        data["branch"] = rootState.aljabr.selectedBranch._id;
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/add`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
  },
};
