<template>
  <v-app>
    <v-system-bar v-if="isAuthenticated" class="white-theme" style="position: fixed; z-index: 5" :style="darkTheme ? 'background-color: #151515; border-bottom: 1px solid #292929;' : 'background-color: #f1f1f1; border-bottom: 1px solid #e6e6e6;'">
      <v-app-bar-nav-icon v-if="isAuthenticated" style="margin-right: 0.3rem" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-select
        v-if="isAuthenticated"
        dense
        hide-details
        style="margin-top: 0; max-width: 10rem"
        solo
        flat
        background-color="transparent"
        :items="companies"
        v-model="company"
        return-object
        item-value="_id"
        :readonly="companies.length < 2"
        :append-icon="companies.length < 2 ? '' : 'mdi-menu-down'"
        :style="companies.length < 2 ? '-webkit-app-region: drag' : ''"
      >
        <template v-slot:item="{ item }">
          <v-row align="center" justify="center" class="my-3">
            <v-col cols="auto" class="pa-0 mr-5">
              <v-avatar size="32" rounded>
                <v-img contain :src="item.logos.base64"></v-img>
              </v-avatar>
            </v-col>
            <v-col class="pa-0">
              <p class="mb-0 title">{{ item.title }}</p>
            </v-col>
          </v-row>
        </template>
        <template v-slot:selection="{ item }">
          <v-row align="center" justify="center" class="my-3">
            <v-col cols="auto" class="pa-0 mr-5">
              <v-avatar size="32" rounded>
                <v-img contain :src="item.logos.base64"></v-img>
              </v-avatar>
            </v-col>
            <v-col class="pa-0">
              <p class="mb-0 title">{{ item.title }}</p>
            </v-col>
          </v-row>
        </template>
      </v-select>
      <v-spacer></v-spacer>
      <div class="d-none d-sm-flex" v-if="isAuthenticated">
        <v-row justify="end" v-if="company.title == 'Al Jabr'">
          <v-col cols="auto" class="py-0 pr-4 pl-0">
            <v-select
              :no-data-text="langs[userLanguage].select.branches.noDataText"
              v-if="canSelectAlJabrBranch"
              style="font-weight: bold; font-size: large"
              :items="aljabr.branchesList"
              :label="langs[userLanguage].select.branches.label"
              dense
              solo
              hide-details
              v-model="alJabrBranch"
              item-text="name"
              item-value="_id"
              background-color="transparent"
              flat
            ></v-select>
          </v-col>
          <v-col cols="auto" class="py-0 pr-4 pl-0">
            <v-select
              :no-data-text="langs[userLanguage].select.academicYears.noDataText"
              v-if="canSelectAlJabrAcademicYear"
              style="width: 10rem; font-weight: bold; font-size: large"
              :items="aljabr.academicYearsList"
              :label="langs[userLanguage].select.academicYears.label"
              dense
              solo
              hide-details
              v-model="alJabrAcademicYear"
              item-text="year"
              item-value="_id"
              background-color="transparent"
              flat
            ></v-select>
          </v-col>
        </v-row>
        <v-row justify="end" v-if="company.title == 'ABCO'">
          <v-col cols="auto" class="py-0 pr-4 pl-0">
            <v-select
              :no-data-text="langs[userLanguage].select.branches.noDataText"
              v-if="canSelectABCOBranch"
              style="font-weight: bold; font-size: large"
              :items="abco.branchesList"
              :label="langs[userLanguage].select.branches.label"
              dense
              solo
              hide-details
              v-model="ABCOBranch"
              item-text="name"
              item-value="_id"
              background-color="transparent"
              flat
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </v-system-bar>
    <v-navigation-drawer v-if="isAuthenticated" v-model="drawer" absolute app temporary style="z-index: 1000">
      <v-list-item>
        <v-list-item-avatar rounded>
          <v-img :src="avatar" aspect-ratio="1" :alt="fullName"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ fullName }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <div class="d-flex d-sm-none mt-3">
        <v-row justify="center" v-if="company.title == 'Al Jabr'">
          <v-col cols="12" class="py-0 px-2">
            <v-select
              :no-data-text="langs[userLanguage].select.branches.noDataText"
              v-if="canSelectAlJabrBranch"
              style="font-weight: bold; font-size: large"
              :items="aljabr.branchesList"
              :label="langs[userLanguage].select.branches.label"
              dense
              solo
              hide-details
              v-model="alJabrBranch"
              item-text="name"
              item-value="_id"
              background-color="transparent"
              flat
            ></v-select>
          </v-col>
          <v-col cols="12" class="py-0 px-2">
            <v-select
              :no-data-text="langs[userLanguage].select.academicYears.noDataText"
              v-if="canSelectAlJabrAcademicYear"
              style="width: 10rem; font-weight: bold; font-size: large"
              :items="aljabr.academicYearsList"
              :label="langs[userLanguage].select.academicYears.label"
              dense
              solo
              hide-details
              v-model="alJabrAcademicYear"
              item-text="year"
              item-value="_id"
              background-color="transparent"
              flat
            ></v-select>
          </v-col>
        </v-row>
        <v-row justify="end" v-if="company.title == 'ABCO'">
          <v-col cols="12" class="py-0 px-2">
            <v-select
              :no-data-text="langs[userLanguage].select.branches.noDataText"
              v-if="canSelectABCOBranch"
              style="width: 10rem; font-weight: bold; font-size: large"
              :items="abco.branchesList"
              :label="langs[userLanguage].select.branches.label"
              dense
              solo
              hide-details
              v-model="ABCOBranch"
              item-text="name"
              item-value="_id"
              background-color="transparent"
              flat
            ></v-select>
          </v-col>
        </v-row>
      </div>
      <v-list nav dense>
        <v-list-item-group color="primary">
          <v-list-item v-for="(menu, index) in menus" :key="index" router :to="menu.to">
            <v-list-item-icon>
              <v-icon v-text="menu.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="menu.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-icon>
              <v-icon v-text="'mdi-exit-to-app'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="langs[userLanguage].exit"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main :style="darkTheme ? 'background-color: #151515' : 'background-color: #f1f1f1'" style="padding-top: 3rem">
      <v-overlay :value="!onLine" absolute opacity=".8">
        <p class="display-4">{{ langs[this.userLanguage].noNetwork }}</p>
      </v-overlay>
      <v-container fluid style="height: 100%">
        <router-view />
      </v-container>
    </v-main>
    <v-dialog fullscreen v-model="selectAlJabrBranchAndAcademicYearDialog" persistent style="z-index: 1000">
      <v-card>
        <v-card-text style="height: 100vh; display: flex; align-content: center; justify-content: center; align-items: center">
          <v-row class="d-flex align-content-center justify-center center">
            <v-col cols="12">
              <p class="display-2 text-center">Al Jabr filiali va o'quv yilini tanlang</p>
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select outlined hide-details v-model="selectedAlJabrBranch" :items="aljabr.branchesList" item-value="_id" item-text="name" label="Filial"></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select :disabled="!!!aljabr.academicYearsList.length" outlined hide-details v-model="selectedAlJabrAcademicYear" :items="aljabr.academicYearsList" item-value="_id" item-text="year" label="O'quv yili"></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import moment from "moment";

export default {
  name: "App",
  data: () => ({
    onLine: navigator.onLine,
    appSettingDialog: false,
    updateBadge: false,
    userMenu: false,
    update: { update: false },
    percent: 0,
    drawer: false,
    langs: {
      uz: {
        noNetwork: "Tarmoqga ulanish mavjud emas...",
        errors: {
          getStudentsCount: "Statistika uchun talabalar sonini olishda xatolik!",
          getGroupsCount: "Statistika uchun guruhlar sonini olishda xatolik!",
          getTeachersCount: "Statistika uchun o'qituvchilar sonini olishda xatolik!",
          getStaffersCount: "Statistika uchun xodimlar sonini olishda xatolik!",
        },
        select: {
          branches: {
            noDataText: "Filiallar mavjud emas!",
            label: "Filiallar",
          },
          academicYears: {
            noDataText: "Akademik yillar mavjud emas!",
            label: "Akademik yillar",
          },
        },
        exit: "Akkountdan chiqish",
      },
      ru: {
        noNetwork: "Нет подключения к сети...",
        errors: {
          getStudentsCount: "Ошибка получения количества студентов для статистики!",
          getGroupsCount: "Ошибка получения количества групп для статистики!",
          getTeachersCount: "Ошибка получения количества учителей для статистики!",
          getStaffersCount: "Ошибка получения количества сотрудников для статистики!",
        },
        select: {
          branches: {
            noDataText: "Нет филиалов!",
            label: "Филиалы",
          },
          academicYears: {
            noDataText: "Учебные годы отсутствуют!",
            label: "Учебные годы",
          },
        },
        exit: "Выйти из аккаунта",
      },
    },
    selectAlJabrBranchAndAcademicYearDialog: false,
  }),
  methods: {
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
      if (type === "online") {
        document.removeEventListener("keydown", this.stopTab);
      } else {
        document.addEventListener("keydown", this.stopTab);
      }
    },
    stopTab(e) {
      if (e.keyCode == 9) {
        e.preventDefault();
      }
    },
    minimizeApp() {
      ipcRenderer.send("minimizeApp");
    },
    maximizeResizeApp() {
      ipcRenderer.send("maximizeResizeApp");
    },
    closeApp() {
      ipcRenderer.send("closeApp");
    },
    openAppSettingDialog() {
      this.appSettingDialog = true;
    },
    closeAppSettingsDialog() {
      this.appSettingDialog = false;
    },
    logout() {
      this.$store.dispatch("LOGOUT");
    },
    getAllAlJabrBranches() {
      this.$store
        .dispatch("aljabr/branches/profile", { profile: this.$store.state.profile._id, company: "aljabr" })
        .then((result) => {
          this.aljabr.branchesList = result.data;

          if (!!!result.data.length) {
            this.$router.push({ name: "AlJabrBranches" }).catch(() => {});
          } else {
            sessionStorage.setItem("branchesList", JSON.stringify([result.data]));
            if (!sessionStorage.getItem("aljabrBranch")) {
              if (result.data.length > 1) {
                sessionStorage.setItem("aljabrBranch", JSON.stringify(this.aljabr.branchesList[0]));
                this.aljabr.selectedBranch = this.aljabr.branchesList[0];
                this.aljabr.canSelectBranch = true;
              } else {
                sessionStorage.setItem("aljabrBranch", JSON.stringify(this.aljabr.branchesList[0]));
                this.aljabr.selectedBranch = this.aljabr.branchesList[0];
              }
            } else {
              const branch = JSON.parse(sessionStorage.getItem("aljabrBranch"));
              this.aljabr.selectedBranch = branch;
              if (result.data.length > 1) {
                this.aljabr.canSelectBranch = true;
              }
            }

            this.getAllAlJabrAcademicYears();
          }
        })
        .catch((error) => {
          this.$toast.error("Filiallar ro'yxatini olishda xatolik!");
        });
    },
    getAllAlJabrAcademicYears(branchChanged) {
      if (this.aljabr.selectedBranch._id) {
        this.$store
          .dispatch("aljabr/academicYears/list")
          .then((result) => {
            this.aljabr.academicYearsList = result.data;
            this.aljabr.canSelectAcademicYear = true;

            if (!!!result.data.length) {
              this.$router.push({ name: "AlJabrAcademicYears" }).catch(() => {});
            } else {
              if (!sessionStorage.getItem("aljabrAcademicYear")) {
                const prevYear = moment().utc(true).subtract(1, "year").format("YYYY");
                const year = moment().utc(true).format("YYYY");
                const nextYear = moment().utc(true).add(1, "year").format("YYYY");
                const month = moment().utc(true).format("MM");
                let _academicYear = `${prevYear}/${year}`;

                if (month >= 9 && month <= 12) {
                  _academicYear = `${year}/${nextYear}`;
                } else if (month <= 1 && month >= 6) {
                  _academicYear = `${prevYear}/${year}`;
                }

                const academicYear = this.aljabr.academicYearsList.find((item) => item.year.includes(_academicYear));

                if (academicYear) {
                  this.aljabr.selectedAcademicYear = academicYear;
                } else {
                  this.aljabr.selectedAcademicYear = result.data[0];
                }
              }

              if (branchChanged) {
                const prevYear = moment().utc(true).subtract(1, "year").format("YYYY");
                const year = moment().utc(true).format("YYYY");
                const nextYear = moment().utc(true).add(1, "year").format("YYYY");
                const month = moment().utc(true).format("MM");
                let _academicYear = `${prevYear}/${year}`;

                if (month >= 9 && month <= 12) {
                  _academicYear = `${year}/${nextYear}`;
                } else if (month <= 1 && month >= 6) {
                  _academicYear = `${prevYear}/${year}`;
                }

                const academicYear = this.aljabr.academicYearsList.find((item) => item.year == _academicYear);

                if (academicYear) {
                  this.aljabr.selectedAcademicYear = academicYear;
                } else {
                  this.aljabr.selectedAcademicYear = result.data[0];
                }

                sessionStorage.setItem("aljabrAcademicYear", JSON.stringify(this.aljabr.selectedAcademicYear));
              }
            }
          })
          .catch((e) => {
            console.log(e);
            this.$toast.error("Akademik yillar ro'yxatini olishda xatolik!");
          });
      }
    },
    getAllACBOBranches() {
      this.$store
        .dispatch("abco/branches/list")
        .then((result) => {
          console.log("🚀 ~ .then ~ result:", result);
          this.ABCO.branches = result.data;

          if (result.data && result.data.length == 1) {
            this.$store.state.ABCO.branch = result.data[0];
            sessionStorage.setItem("ABCOBranch", JSON.stringify(result.data[0]));
          }
        })
        .catch((e) => {
          this.$toast.error("Filiallar ro'yxatini olishda xatolik!");
        });
    },
    getData() {
      this.$eventHub.$on("updateAlJabrBranchesLists", this.getAllAlJabrBranches);
      this.$eventHub.$on("updateAlJabrAcademicYearsLists", this.getAllAlJabrAcademicYears);
      this.$eventHub.$on("updateABCOBranchesLists", this.getAllACBOBranches);

      if (this.isAuthenticated) {
        setTimeout(() => {
          const e = document.querySelector(".v-select__selections");
          if (e && e.lastChild) {
            e.lastChild.style.display = "none";
          }
        }, 200);

        if (this.company.title == "Al Jabr") {
          if (sessionStorage.getItem("aljabrBranch")) {
            this.aljabr.selectedBranch = JSON.parse(sessionStorage.getItem("aljabrBranch"));
          }

          if (sessionStorage.getItem("aljabrAcademicYear")) {
            this.aljabr.selectedAcademicYear = JSON.parse(sessionStorage.getItem("aljabrAcademicYear"));
          }

          this.getAllAlJabrBranches();
          // this.getAllAlJabrAcademicYears();
        }
        if (this.company.title == "ABCO") {
          this.getAllACBOBranches();
        }
      }
    },
    initialize() {
      window.addEventListener("online", this.updateOnlineStatus);
      window.addEventListener("offline", this.updateOnlineStatus);
      this.$eventHub.$on("getAppData", this.getData);

      if (this.isAuthenticated) {
        this.getData();
      }
    },
  },
  computed: {
    company: {
      get() {
        return this.$store.state.company;
      },
      set(company) {
        this.getData();
        const menus = [];
        let mainPage = "/";

        const routers = this.$router.options.routes.filter((_router) => _router.meta && _router.meta.company == company.short);

        for (let index = 0; index < routers.length; index++) {
          const _router = routers[index];

          for (let i = 0; i < _router.children.length; i++) {
            const children = _router.children[i];
            if (children.meta && children.meta.hasOwnProperty("roles") && company.profile.roles.find((_role) => children.meta.roles.includes(_role.role.name))) {
              menus.push({ title: children.title, to: `${_router.path}/${children.path}`, icon: children.meta.icon });

              if (children.meta.isMain) {
                mainPage = `${_router.path}/${children.path}`;
              }
            }
          }
        }

        sessionStorage.setItem("company", JSON.stringify(company));
        sessionStorage.setItem("profile", JSON.stringify(company.profile));
        sessionStorage.setItem("menus", JSON.stringify(menus));
        this.$store.state.company = company;
        this.$store.state.profile = company.profile;
        this.$store.state.menus = menus;

        this.$router.push(mainPage);

        const role = this.$store.state.profile.roles.reduce((prev, current) => {
          return prev && prev.role.level > current.role.level ? prev : current;
        });

        switch (role.role.name) {
          case "director":
            this.$store.state[company.short].canSelectBranch = true;
            this.$store.state[company.short].canSelectAcademicYear = true;
            this.$eventHub.$emit(`${company.title}GetAllStatisticsData`, null);
            this.$store.state[company.short].role = "director";
            sessionStorage.setItem(`${company.short}Role`, "director");

            break;

          case "admin":
            this.$store.state[company.short].canSelectBranch = true;
            this.$store.state[company.short].canSelectAcademicYear = true;
            this.$store.state[company.short].role = "admin";
            sessionStorage.setItem(`${company.short}Role`, "director");
            break;

          case "teacher":
            this.$store.state[company.short].role = "teacher";
            sessionStorage.setItem(`${company.short}Role`, "teacher");
            break;

          case "cashier":
            this.$store.state[company.short].role = "cashier";
            sessionStorage.setItem(`${company.short}Role`, "cashier");
            break;

          case "staffer":
            this.$store.state[company.short].role = "staffer";
            sessionStorage.setItem(`${company.short}Role`, "staffer");
            break;

          case "student":
            this.$store.state[company.short].role = "student";
            sessionStorage.setItem(`${company.short}Role`, "student");
            break;

          case "manager":
            this.$store.state[company.short].role = "manager";
            sessionStorage.setItem(`${company.short}Role`, "manager");
            break;

          case "security":
            this.$store.state[company.short].role = "security";
            sessionStorage.setItem(`${company.short}Role`, "security");
            break;

          case "organizational-staffer":
            this.$store.state[company.short].role = "organizational-staffer";
            sessionStorage.setItem(`${company.short}Role`, "organizational-staffer");
            break;

          default:
            break;
        }

        this.getData();
      },
    },
    selectedAlJabrBranch: {
      get() {
        return this.aljabr.branchesList.find((branch) => branch._id === this.aljabr.selectedBranch._id);
      },
      set(value) {
        sessionStorage.setItem("aljabrBranch", JSON.stringify(this.aljabr.branchesList.find((branch) => branch._id === value)));
        this.aljabr.selectedBranch._id = value;
        this.getAllAlJabrAcademicYears();
      },
    },
    selectedAlJabrAcademicYear: {
      get() {
        return this.aljabr.academicYearsList.find((academicYear) => academicYear.year === this.aljabr.selectedAcademicYear.year);
      },
      set(value) {
        const data = this.aljabr.academicYearsList.find((academicYear) => academicYear._id === value);
        sessionStorage.setItem("aljabrAcademicYear", JSON.stringify(data));
        this.aljabr.selectedAcademicYear = data;
      },
    },
    companies() {
      return this.$store.state.companies;
    },
    userLanguage() {
      return this.$store.state.userLanguage.short;
    },
    darkTheme() {
      return this.$vuetify.theme.dark;
    },
    isAuthenticated() {
      return this.$store.state.authenticated;
    },
    canSelectAlJabrBranch() {
      return this.aljabr.canSelectBranch;
    },
    canSelectAlJabrAcademicYear() {
      return this.aljabr.canSelectAcademicYear;
    },
    aljabr() {
      return this.$store.state.aljabr;
    },
    abco() {
      return this.$store.state.abco;
    },
    menus() {
      return this.$store.state.menus.sort((a, b) => a.title.localeCompare(b.title));
    },
    roles() {
      return this.$store.state.profile.roles.map((role) => role.role.title);
    },
    fullName() {
      return this.$store.state.profile && this.$store.state.profile.formated && this.$store.state.profile.formated.firstLastName;
    },
    avatar() {
      return `${this.$store.state.serverURL}/${this.$store.state.profile.avatar}`;
    },
    alJabrBranch: {
      get() {
        return this.aljabr.branchesList.find((branch) => branch._id === this.$store.state.aljabr.selectedBranch._id);
      },
      set(value) {
        sessionStorage.setItem("aljabrBranch", JSON.stringify(this.aljabr.branchesList.find((branch) => branch._id === value)));
        const data = this.aljabr.branchesList.find((branch) => branch._id === value);
        this.aljabr.selectedBranch = data;
        this.getAllAlJabrAcademicYears(true);

        // setTimeout(() => {
        //   if (this.alJabrAcademicYear) {
        //     const { year, _id } = this.alJabrAcademicYear;
        //     this.alJabrAcademicYear = _id;
        //   }
        // }, 100);
      },
    },
    alJabrAcademicYear: {
      get() {
        return this.aljabr.academicYearsList.find((academicYear) => academicYear.year === this.aljabr.selectedAcademicYear.year);
      },
      set(value) {
        const data = this.aljabr.academicYearsList.find((academicYear) => academicYear._id === value);
        sessionStorage.setItem("aljabrAcademicYear", JSON.stringify(data));
        this.aljabr.selectedAcademicYear = data;
      },
    },
    alJabrCurrentAcademicYear() {
      return this.$store.aljabr && this.$store.aljabr.selectedAcademicYear && this.$store.aljabr.selectedAcademicYear.year;
    },
    canSelectABCOBranch() {
      return this.abco.canSelectBranch;
    },
    canSelectABCOAcademicYear() {
      return this.abco.canSelectAcademicYear;
    },
    ABCOBranch: {
      get() {
        return this.abco.branchesList.find((branch) => branch._id === this.abco.branch._id);
      },
      set(value) {
        sessionStorage.setItem("abcoBranch", JSON.stringify(this.abco.branchesList.find((branch) => branch._id === value)));
        this.abco.selectedBranch._id = value;
      },
    },
    alJabrAllDataSelected() {
      return this.alJabrBranch && this.alJabrAcademicYear;
    },
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() {
    this.$eventHub.$off("updateAlJabrBranchesLists");
    this.$eventHub.$off("updateAlJabrAcademicYearsLists");
    this.$eventHub.$off("updateABCOBranchesLists");
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
  watch: {
    alJabrAllDataSelected(value) {
      if (value) {
        this.selectAlJabrBranchAndAcademicYearDialog = false;
      }
    },
  },
};
</script>

<style scoped>
.white-theme {
  background-color: #f1f1f1;
  height: 3rem !important;
  user-select: none;
  padding-right: 0;
  width: 100%;
}

.white-logo {
  background: url("http://new.aljabruz.uz/images/app-logo-white-logo.png") no-repeat;
  width: 260px;
  height: 100%;
}

.dark-logo {
  background: url("http://new.aljabruz.uz/images/app-logo-dark-logo.png") no-repeat;
  width: 260px;
  height: 100%;
}

.system-button-minimize {
  position: relative;
  height: 48px;
  width: 46px;
  box-sizing: border-box;
}

.dark-hover-button:hover {
  background-color: #2b2b2b;
}

.white-hover-button:hover {
  background-color: #cecece;
}

.system-button-span {
  display: inline-block;
  vertical-align: middle;
  speak: none;
  height: 12px;
  width: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.system-button-svg {
  height: 100%;
  fill: currentcolor;
  vertical-align: top;
  forced-color-adjust: auto;
}

.system-button-resize {
  position: relative;
  height: 48px;
  width: 46px;
  box-sizing: border-box;
}

.system-button-close {
  position: relative;
  height: 48px;
  width: 46px;
  box-sizing: border-box;
}

.system-button-close:hover {
  background-color: #eb2013;
  color: #fff;
}

.app-settings {
  height: 32px;
  width: 32px;
  margin-right: 4px;
}

.app-settings-button {
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  user-select: none;
  outline: none;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", Helvetica, Arial, sans-serif;
  line-height: 1.334;
  height: 32px;
  background-color: transparent;
  color: rgb(107, 107, 107);
  padding: 0px 8px;
}

.white-hover-app-settings-button:hover {
  background-color: #cecece;
  color: rgb(33, 33, 33);
}

.dark-hover-app-settings-button:hover {
  background-color: #2b2b2b;
  color: rgb(196, 196, 196);
}

.alert-position {
  z-index: 99;
  position: fixed;
  top: 3rem;
  left: 1rem;
  width: 100%;
}

.global-find-input {
  position: fixed;
  left: 38%;
  width: 20%;
  background: transparent;
  min-width: 250px;
  border: transparent 1px solid;
}

.global-find-input:hover {
  border: #363636 1px solid;
}

.branchSelect .v-input__control .v-input__slot {
  box-shadow: none !important;
}
</style>
