const { serialize } = require("../../utils");
export default {
  namespaced: true,
  state: {
    path: "aljabr/payments",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        options["branch"] = rootState.aljabr.selectedBranch._id;
        options["academicYear"] = rootState.aljabr.selectedAcademicYear._id;
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/?${serialize(options)}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    spendings({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        options["branch"] = rootState.aljabr.selectedBranch._id;
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/spendings?${serialize(options)}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    confirmedSpendings({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/confirmed-spendings?${serialize(options)}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    salaries({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        options["branch"] = rootState.aljabr.selectedBranch._id;
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/salaries?${serialize(options)}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    last({ dispatch, state, rootState }) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/last/?branch=${rootState.aljabr.selectedBranch._id}&academicYear=${rootState.aljabr.selectedAcademicYear._id}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    add({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/add`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    paySpending({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/pay-spending`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    save({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/save`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    transfer({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/transfer`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    remove({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/remove`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    confirm({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/confirm`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    generate({ dispatch, state, rootState }, type) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/generate?type=${type}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    notConfirmedPayments({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/not-confirmed-payments?${serialize(options)}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    confirmSpendings({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/confirm-spendings`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    notConfirmedSpendings({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/not-confirmed-spendings?${serialize(options)}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    removeSpending({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/remove-spending`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    paymentsToSpendings({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        options["branch"] = rootState.aljabr.selectedBranch._id;
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/payments-to-spendings?${serialize(options)}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    paySalarySpending({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/pay-salary-spending`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    getForReports({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        options["branch"] = rootState.aljabr.selectedBranch._id;
        options["academicYear"] = rootState.aljabr.selectedAcademicYear._id;
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/forReports?${serialize(options)}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
  },
};
