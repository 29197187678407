const { serialize } = require("../../utils");

export default {
  namespaced: true,
  state: {
    path: "aljabr/telegram-parents",
  },
  actions: {
    auth({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/auth`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    visits({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/visits?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    payments({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/payments?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    appropriations({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/appropriations?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    homeWorkAppropriations({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/homework-appropriations?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    behaviors({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/behaviors?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    survey({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/survey?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    sendSurveyAnswer({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/send-survey-answer`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    getBotReports({ dispatch, state, rootState }) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/get-bot-reports`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    blockUser({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/block-user`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    UnblockUser({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/unblock-user`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    sendMessageToUser({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/send-message-to-user`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    updateUsersCredentionals({ dispatch, state, rootState }) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/update-users-credentionals`,
          method: "POST",
          data: {},
        },
        { root: true }
      );
    },
    getByGroup({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/get-by-groups?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    updateStudentAvatar({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/update-student-avatar`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    removeTelegramBotUser({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/remove-telegram-bot-user`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    lessonsSchedules({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/lessons-schedules?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    reInitParentChildrens({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/re-init-parent-childrens`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    reInitParentBotButtons({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/re-init-parents-bot-buttons`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    chats({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/chats?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    addNewChat({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add-new-chat`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    addNewMessageToChat({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add-new-message-to-chat`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    ratingChat({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/rating-chat`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    canSendChat({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/can-send-chat?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
  },
};
