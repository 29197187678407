import branches from "./branches";
import academicYears from "./academicYears";
import statistics from "./statistics";
import groups from "./groups";
import teachers from "./teachers";
import roles from "./roles";
import staffers from "./staffers";
import students from "./students";
import contracts from "./contracts";
import payments from "./payments";
import stafferTasks from "./stafferTasks";
import employeeRegistrations from "./employeeRegistrations";
import telegram from "./telegram";
import telegramParents from "./telegramParents";
import directorMyWeeks from "./directorMyWeeks";
import sciences from "./sciences";
import migration from "./migration";
import telegramSurveys from "./telegramSurveys";
import salaries from "./salaries";
import lessonsSchedules from "./lessonsSchedules";
import faceIdDevices from "./faceIdDevices";

export default {
  namespaced: true,
  state: {
    branchesList: [],
    selectedBranch: { _id: null, name: null },
    canSelectBranch: false,
    academicYearsList: [],
    selectedAcademicYear: { _id: null, year: "" },
    canSelectAcademicYear: false,
    role: null,
    userRoles: [],
  },
  modules: {
    branches,
    academicYears,
    staffers,
    statistics,
    groups,
    teachers,
    roles,
    students,
    contracts,
    payments,
    stafferTasks,
    employeeRegistrations,
    telegram,
    telegramParents,
    directorMyWeeks,
    sciences,
    migration,
    telegramSurveys,
    salaries,
    lessonsSchedules,
    faceIdDevices,
  },
};
