export default {
  namespaced: true,
  state: {
    path: "companies",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    withRoles({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/with-roles`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    id({ dispatch, state, rootState }, id) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/id/${id}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    add({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
  },
};
