const { serialize } = require("../../utils");
export default {
  namespaced: true,
  state: {
    path: "aljabr/statistics",
  },
  actions: {
    studentsCount({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/students-count?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    groupsCount({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/groups-count?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    teachersCount({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/teachers-count?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    staffersCount({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/staffers-count?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    todayPayments({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/today-payments?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
  },
};
