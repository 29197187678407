import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import _package from "../../package.json";
import aljabr from "./aljabr";
import abco from "./ABCO";
import allCompanies from "./companies";
import users from "./users";

const serverURL = _package.server[process.env.NODE_ENV] || "http://192.168.0.200:5000";
const APIURL = "/platform/api/v1/";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    aljabr,
    abco,
    allCompanies,
    users,
  },
  state: {
    serverURL,
    APIURL,
    authenticated: false,
    AID: null,
    userLanguage: { short: "uz", title: "O'zbek" },
    profile: { avatar: "", roles: [] },
    menus: [],
    ABCO: {
      branch: { _id: null, name: null },
      canSelectBranch: false,
      role: "",
    },
    companies: [],
    company: { title: "", description: "", logo: "", _id: "" },
    appVersion: _package.version,
    appName: _package.appName,
    appAuthor: _package.author,
    currentPage: "login",
  },
  actions: {
    FETCH({ dispatch, commit, state }, { url, method, data }) {
      console.log("FETCH", url, method, data);
      const token = sessionStorage.getItem("token");
      const options = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: token,
        },
        method: "GET",
      };

      if (method != "GET" && method != "VID") {
        options.method = method;
        options.body = JSON.stringify(data);
      }

      if (method == "VID") {
        options.method = "POST";
        options.body = data;

        delete options.headers["Content-Type"];
      }

      return new Promise((resolve, reject) => {
        fetch(`${serverURL}${APIURL}${url}`, options)
          .then(async (response) => {
            if (response.ok) {
              const json = await response.json();
              resolve(json);
            } else {
              console.log(response);
              if (response.status == 401) {
                state.currentPage = "unauth";
                dispatch("LOGOUT");
                reject({ message: { uz: "Sessiya vaqti tugadi, iltimos qaytadan kiring!", ru: "Время сеанса истекло, пожалуйста, войдите снова!" } });
              } else {
                const text = await response.json();

                console.log("JSON NOT OK", text);
                reject(text);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    LOGIN({ dispatch, commit, state }, { aid, password }) {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await dispatch("FETCH", { url: "users/login", method: "POST", data: { aid, password } });

          console.log("Login result:", result);
          state.authenticated = true;
          state.AID = aid;
          state.token = result.token;
          state.companies = result.companies.map((_company) => ({ ..._company.company, profile: _company.profile }));
          sessionStorage.setItem("aid", result.aid);
          sessionStorage.setItem("token", result.token);
          sessionStorage.setItem("companies", JSON.stringify(state.companies));

          resolve({ showSelectCompanyDialog: !!!sessionStorage.getItem("company"), ...result });
        } catch (error) {
          reject(error);
        }
      });
    },
    LOGOUT({ commit, state }) {
      sessionStorage.removeItem("aid");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("profile");
      sessionStorage.removeItem("menus");
      sessionStorage.removeItem("companies");
      sessionStorage.removeItem("company");
      sessionStorage.removeItem("role");
      sessionStorage.removeItem("aljabrRole");
      sessionStorage.removeItem("abcoRole");
      sessionStorage.removeItem("aljabrBranch");
      sessionStorage.removeItem("aljabrRoles");
      sessionStorage.removeItem("branchesList");
      sessionStorage.removeItem("aljabrAcademicYear");
      state.AID = null;
      state.authenticated = false;
      state.profile = { avatar: "", roles: [] };
      state.token = null;
      state.menus = [];
      state.aljabr.canSelectAcademicYear = false;
      state.aljabr.canSelectBranch = false;
      state.aljabr.role = "";
      state.abco.canSelectBranch = false;
      state.abco.role = "";
      state.company = { title: "", description: "", logo: "", _id: "" };

      router.go("/login");
    },
    INIT_AUTH({ state }) {
      return new Promise((resolve) => {
        const aid = sessionStorage.getItem("aid");

        if (aid) {
          state.authenticated = true;
          state.AID = aid;
          state.profile = JSON.parse(sessionStorage.getItem("profile"));
          state.menus = JSON.parse(sessionStorage.getItem("menus"));
          state.companies = JSON.parse(sessionStorage.getItem("companies"));
          state.token = sessionStorage.getItem("token");
          state.company = JSON.parse(sessionStorage.getItem("company"));

          if (sessionStorage.getItem("aljabrRoles")) {
            state.aljabr.userRoles = JSON.parse(sessionStorage.getItem("aljabrRoles"));
          }

          if (sessionStorage.getItem("abcoRoles")) {
            state.abco.userRoles = JSON.parse(sessionStorage.getItem("abcoRoles"));
          }

          if (sessionStorage.getItem("aljabrBranch")) {
            state.aljabr.branch = JSON.parse(sessionStorage.getItem("aljabrBranch"));
          }

          if (sessionStorage.getItem("aljabrAcademicYear")) {
            state.aljabr.academicYear = JSON.parse(sessionStorage.getItem("aljabrAcademicYear"));
          }

          if (sessionStorage.getItem("aljabrRole")) {
            state.aljabr.role = sessionStorage.getItem("aljabrRole");
          }

          if (sessionStorage.getItem("abcoBranch")) {
            state.abco.selectedBranch = JSON.parse(sessionStorage.getItem("abcoBranch"));
          }

          if (sessionStorage.getItem("abcoRole")) {
            state.abco.role = sessionStorage.getItem("abcoRole");
          }

          resolve(true);
        } else {
          resolve(false);
        }
      });
    },
    ERROR({ state }, { error, errorMessage }) {
      if (state.authenticated) {
        if (error.hasOwnProperty("uz") && error.hasOwnProperty("ru") && state.userLanguage && state.userLanguage.short) {
          Vue.$toast.error(error[state.userLanguage.short]);
        } else {
          Vue.$toast.error(errorMessage);
        }
      }
    },
  },
});
